import styled from 'styled-components'
import { Column } from '@shipt/design-system-layouts'
import { spacing } from '@/theme/tokens'
import { type Size, screenSizes } from '@shipt/design-system-themes'

export const GapManager = styled(Column).attrs({ align: 'center' })<{
  verticalSpacing?: Size | 'none'
}>`
  --cms-gap: ${({ verticalSpacing }) =>
    verticalSpacing === 'none' ? 0 : spacing(verticalSpacing ?? 120)};

  gap: var(--cms-gap);
  margin: calc(var(--cms-gap) / 2) 0;

  & > .no-margin {
    margin: calc(var(--cms-gap) / -2);
  }

  & > .with-background {
    margin: calc(var(--cms-gap) / -2);
    padding: calc(var(--cms-gap) / 4) 0;

    @media ${screenSizes.tablet} {
      padding: calc(var(--cms-gap) / 2);
    }
  }
`
